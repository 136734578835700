import React from "react";
// eslint-disable-next-line import/no-unresolved
import { ThemeProvider, Title, Paragraph} from "gigapay-ui";
import "./styles/base/style.css";
import logo from "./assets/images/logo.svg";
import Simple from "./components/Simple";
 

function App() {

    const PARAGRAPH_1 = "DAC7 puts new requirements on platforms to verify certain data about companies and people that are selling services, selling goods, and renting out vehicles or accomodation. The data should be verified against services offered by the EU or any of the member states.";
    const VIES = <a href="https://ec.europa.eu/taxation_customs/vies/">VIES</a>;
    const hereLink = <a href="https://gigapay.co/how-dac7-affects-you/">here</a>;
    const FAQLink = <a href="https://gigapay.co/faq-verification-tool/">FAQ</a>;
    const contactFormLink = <a href="https://gigapay.co/how-dac7-affects-you/#talk-to-us">this form</a>;
    return (
        <ThemeProvider theme={{}}>
            <nav >
                <a href="https://gigapay.co/" target="_blank" rel="noreferrer">
                    <img src={logo} className="logoImage" />
                </a>

            </nav>

            <main className="content">
                <div className="center">
                    <Title small>DAC7 - Verification Tool</Title>
                    <section className ="paragraph"> 
                        <Paragraph >
                            {PARAGRAPH_1}
                        </Paragraph>
                        <Paragraph>
                        Using the tool below you can test if your VAT numbers are present in the {VIES} (the European VAT Information Exchange System) or test the structure of the TIN numbers for individuals as well as companies in different EU countries. Fill in the requested information in the fields below to test the numbers, or learn more about DAC7 {hereLink}.
                        </Paragraph>
                    </section>
                    <Simple/>
                </div>
            </main>

            <footer>
                <section className ="paragraph">
                    <Paragraph>
                    Having troubles verifying the VAT number or the TIN? Look in our {FAQLink} to find more information or contact us at <a>dac7@gigapay.co</a> or by filling in {contactFormLink}.
                    </Paragraph>
                </section>
                <ul>
                    <li>© Gigapay 2022</li>
                    <li>                    
                        <a href={"https://gigapay.co/how-dac7-affects-you/"}>
                        Learn more about Dac7
                        </a>
                    </li>
                    <li>                    
                        <a href={"https://gigapay.co/how-dac7-affects-you/"}>
                        Signup for early access to API
                        </a>
                    </li>
                    <li>                    
                        <a href={"https://gigapay.co/contact-2/"}>
                        Contact us
                        </a>
                    </li>
                </ul>
            </footer>
        </ThemeProvider>
    );
}

export default App;
