import React, {useState, useEffect} from "react";
import { Icon, Input, Button, Select, RadioList, Loader } from "gigapay-ui";
import CountryList from "../assets/CountryList";

interface iResults {
    id: number;
    lookups: iLookups;
}
interface iLookups {
    tin: string;
    vat: string;
    checks: Array<[]>
}

export default function Simple() {
    const API_ENDPOINT = `${process.env.REACT_APP_API_HOST}/api/orders/`;

    const [input, setInput] = useState("");
    const [activeRadio, setActiveRadio] = useState<string>("vat");
    const [results, setResults] = useState<iResults | any>({});
    const [countryCode, setCountryCode] = useState("SE");
    const [errors, setErrors] = useState<any>({});
    const [status, setStatus] = useState <number>();
    const [hasclicked, setHasClicked] = useState(false);

    const query = [{
        "tin": activeRadio ==="tin" ? input : "" ,
        "vat": activeRadio ==="vat" ? input : "" ,
        "country": activeRadio ==="tin" ? countryCode : ""
    }];

    const sleep = <T extends any>(
        delay: number,
        resolveValue: T
    ): Promise<T> =>
            new Promise((resolve) => {
                setTimeout(() => resolve(resolveValue), delay);
            });
    
    function handleClick() {
        setHasClicked(true);
        setStatus(0);
        if (!input) {
            setErrors({...errors, inputError: "This field should not be empty."});
            return;
        }
        fetch(API_ENDPOINT, {
            method: "POST", 
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(query)
        })
            .then(response => response.json())
            .then(data => {
                if(!data || !data.order) {
                    console.log("Failed to fetch data.");
                    return;
                }
                return data;
            })            
            .then(data => sleep(2000, data))
            .then(data => {
                fetchData(data.order);
            })
            .catch((error) => {
                console.error("Error fetching Order Number:", error);
            });
    }
    
    const fetchData = async(orderNumber: string) => {
        fetch(`${API_ENDPOINT}${orderNumber??""}/`, {
            method: "GET", 
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                setResults(data?? {lookups:[]});
                setStatus(1);
            })
            .catch((error) => {
                setErrors({...errors, fetchData: error});
                setStatus(2);        
            });
    };

    useEffect(() => {
        const keyDownHandler = (event: { key: string; preventDefault: () => void; }) => {
    
            if (event.key === "Enter") {
                event.preventDefault();
                handleClick();
            }
        };
    
        document.addEventListener("keydown", keyDownHandler);
    
        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [input, activeRadio, countryCode]);
    
    const checks = results?.lookups?.[0].checks;
    const VAT_or_TIN = activeRadio === "tin" ? checks?.flatMap((o: { name: string; }) => o.name !== "check_vat_exists" ? [o]: [])
        :checks?.flatMap((o: { name: string; }) => o.name === "check_vat_exists" ? [o]: []);    
    
    const reduced = VAT_or_TIN?.reduce(function(filtered: any, option: any) {
        if (option.progress !==2) {
            filtered.push( {icon: <Loader small inline/>, description: "Currently verifying. It might takes a few minutes"});
        }
        if (option.result && option.progress ===2) {
            filtered.push( {icon: <Icon name={"checkmark-circle"} color={"#30D66E"} size={23}/>,description: option.description});
        }if (option.progress ===2 && !option.result ) {
            filtered.push({icon: <Icon name={"close-circle"} color={"#FF0237"} size={23}/>, description: option.description});
        }
        return filtered;
    }, []);

    const getButtonLabel = () => {
        if (!hasclicked){
            return "Search";
        }
        if (hasclicked && status ===1){
            return "✅ Searched";
        }
        if (status ===0){
            return "Searching";
        }
    };

    const whatHaveChecked = reduced && reduced
        .map((option: { description: string; icon: Object }) => option)
        .map((v: { description: string, icon: any}, index: number) =>  
            <li key={index}>
                <span> {v.icon} {v.description}</span>
            </li>                        
        );

    return (
        <>
            <section className="searchBar" >
                <Input
                    leftIcon={<Icon name={"search"} size={26} />}
                    label={"TIN or VAT number"}
                    value={input}
                    onChange={(input: any) => {
                        setInput(input); 
                        setHasClicked(false);
                    }}
                    autoComplete={"off"}
                    error={errors.inputError}
                />
            </section>
            <section className="options">
                
                <div className="radioOption">                
                    <RadioList
                        options={[
                            {
                                label: "VAT",
                                value: "vat"
                            },
                            {
                                label: "TIN",
                                value: "tin"
                            }
                        ]}
                        value={activeRadio}
                        onChange={(value: React.SetStateAction<string>) => {
                            setActiveRadio(value);
                            setHasClicked(false);
                        }}
                    />
                </div>
                {(activeRadio ==="tin") &&                 
                <Select
                    value={"SE"}
                    label={"Country"}
                    Icon={<Icon name={"globe"} color={"#A8A8A8"} size={23} />}
                    onChange = {(country: { value: React.SetStateAction<string>; }) => setCountryCode(country.value)}
                    onBlur={() => { }}
                    onFocus={() => { }}
                    options={CountryList.map((country) => ({
                        value: country.code,
                        label: country.name,
                        icon: country.flag
                    }))}
                />    
                }
            </section>

            <section className="cta">
                <Button.Primary responsive onClick = {handleClick} 
                    disabled={!input}
                    loading={status ===0}
                >
                    {getButtonLabel()}
                </Button.Primary>
            </section>

            {status === 0 && <Loader/>}
            {status === 2 && errors.fetchData}
            {status === 1 &&  hasclicked &&         
            <section className="results">                
                <h3>
                    {activeRadio === "vat" ? "VAT number " : "TIN number "}
                    <span style={{fontFamily: "Arial"}}>{input} </span>
                </h3> 
                <ul > {whatHaveChecked}</ul>

            </section>
            }
        </>
    );
}
