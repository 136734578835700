var CountryList = 
[
    {name:"Austria",code:"AT",vat: 20, flag: "🇦🇹"
    },
    {name:"Belgium",code:"BE",vat: 21, flag: "🇧🇪 "
    },
    {name:"Bulgaria",code:"BG",vat: 20, flag: "🇧🇬"
    },
    {name:"Croatia",code:"HR",vat: 25, flag: "🇭🇷"
    },
    {name:"Cyprus",code:"CY",vat: 19, flag:"🇨🇾"
    },
    {name:"Czech Republic",code:"CZ",vat: 21, flag: "🇨🇿"
    },
    {name:"Denmark",code:"DK",vat: 25, flag: "🇩🇰"
    },
    {name:"Estonia",code:"EE",vat: 20, flag: "🇪🇪"
    },
    {name:"Finland",code:"FI",vat: 24, flag: "🇫🇮"
    },
    {name:"France",code:"FR",vat: 20, flag: "🇫🇷"
    },
    {name:"Germany",code:"DE",vat: 19, flag: "🇩🇪"
    },
    {name:"Greece",code:"EL",vat: 24, flag: "🇬🇷"
    },
    {name:"Hungary",code:"HU",vat: 27, flag: "🇭🇺"
    },
    {name:"Ireland",code:"IE",vat: 23, flag: "🇮🇪"
    },
    {name:"Italy",code:"IT",vat: 22, flag: "🇮🇹"
    },
    {name:"Latvia",code:"LV",vat: 21, flag:"🇱🇻"
    },
    {name:"Lithuania",code:"LT",vat: 21, flag:"🇱🇹"
    },
    {name:"Luxembourg",code:"LU",vat: 17, flag:"🇱🇺"
    },
    {name:"Malta",code:"MT",vat: 18, flag:"🇲🇹"
    },
    {name:"Netherlands",code:"NL",vat: 21, flag:"🇳🇱"
    },
    {name:"Poland",code:"PL",vat: 23, flag:"🇵🇱"
    },
    {name:"Portugal",code:"PT",vat: 23, flag:"🇵🇹"
    },
    {name:"Romania",code:"RO",vat: 19, flag:"🇷🇴"
    },
    {name:"Slovakia",code:"SK",vat: 20, flag:"🇸🇰"
    },
    {name:"Slovenia",code:"SI",vat: 22, flag:"🇸🇮"
    },
    {name:"Spain",code:"ES",vat: 21, flag:"🇪🇸"
    },
    {name:"Sweden",code:"SE",vat: 25, flag:"🇸🇪"
    }
];

export default CountryList;